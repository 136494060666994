// Build information, automatically generated by `ng-info`
const build = {
    version: "0.0.0",
    timestamp: "Mon Nov 04 2024 16:29:42 GMT+0000 (Coordinated Universal Time)",
    message: null,
    git: {
        user: null,
        branch: "HEAD",
        hash: "82245c",
        fullHash: "82245ce6f835ea1d47ec63aae64ae53dc3b1292a"
    }
};

export default build;